import Parse from '../../../parse';
import i18n from '../../../translations';
import {
  GetNonSubjectUsersResponse,
  InternalUserRoles,
  TeleFootXRoles,
} from '../../../types/cloud';
import {
  type CustomColumnDefintion,
  InternalUserRolesColumns,
} from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const ROLE_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'internalUser',
    headerName: i18n.t('setInternalUserRoles.internalUser'),
  },
  { column: 'dateAdded', headerName: i18n.t('setInternalUserRoles.dateAdded') },
  {
    column: 'role',
    headerName: i18n.t('setInternalUserRoles.role'),
  },
];

// call the cloud function to get
export const formatAllNonSubjectUsers = async (
  users: GetNonSubjectUsersResponse,
) => {
  const userRows: InternalUserRolesColumns[] = users.map(
    ({ user, roles }: { user: Parse.User; roles: TeleFootXRoles[] }) =>
      ({
        id: user.id, // this is used for row selection
        internalUser: user.get('username'),
        dateAdded: user.get('createdAt'),
        // only show internal roles and after translation
        role: roles
          .filter(r => Object.keys(InternalUserRoles).includes(r))
          .map(r => i18n.t(`setInternalUserRoles.roles.${r}`)),
      }) as InternalUserRolesColumns,
  );
  return userRows;
};
