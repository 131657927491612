import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../../components/templates/Table/Table';
import { useLoading } from '../../../providers/LoadingProvider';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import {
  type CustomTableColumns,
  type ExternalUserRolesColumns,
  SortOrder,
} from '../../../types/tables';
import { ROLE_COLUMNS, formatAllNonSubjectUsers } from './helpers';
import SetUserRoleStyles from './SetExternalUserRolesStyles';
import DropDownMultiSelect from '../../../components/atoms/DropDownSelect/DropDownMultiSelect';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import i18n from '../../../translations';
import { useTableColumns } from '../../../hooks/useTableColumns';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import {
  ExternalUserRoles,
  GetNonSubjectUsersResponse,
  RoleOperation,
} from '../../../types/cloud';

export default function SetExternalUserRoles() {
  const { styles } = useThemedComponent([commonTableStyles, SetUserRoleStyles]);
  const { setLoading } = useLoading();
  const { cloudService } = useCloudContext();
  const {
    columnHelper,
    tableData,
    setTableData,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
    ExportDropDown,
  } = useTableColumns<ExternalUserRolesColumns>({
    defaultSorting: [
      { colKey: 'externalUser', index: 0, order: SortOrder.ASC },
    ],
    tableName: i18n.t('setExternalUserRoles.title'),
  });

  const [grantRoleSelectionIndexes, setGrantRoleSelectionIndexes] = useState<
    number[]
  >([]);
  const [revokeRoleSelectionIndexes, setRevokeRoleSelectionIndexes] = useState<
    number[]
  >([]);

  /**
   * Fetch data for table
   */
  const dataFetcher = useCallback(async () => {
    setLoading(true);
    try {
      const nonSubjectUsers = await cloudService.run<
        Promise<GetNonSubjectUsersResponse>
      >(KNOWN_CLOUD_FUNCTIONS.GET_NON_SUBJECT_USERS);

      const fetchedData = await formatAllNonSubjectUsers(nonSubjectUsers);
      if (fetchedData) {
        // set the data for the component to use
        setTableData(fetchedData);
      }
    } catch (e) {
      alert(`Failed to get non-subject users ${e}`);
    } finally {
      setLoading(false);
    }
  }, [cloudService, setLoading, setTableData]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  /**
   * Set selected roles for selected users vid cloud function
   */
  const onSelectRoles = useCallback(
    async (operation: RoleOperation) => {
      const selectionIndexes =
        operation === RoleOperation.ADD
          ? grantRoleSelectionIndexes
          : revokeRoleSelectionIndexes;
      if (
        selectionIndexes.length === 0 ||
        Object.keys(rowSelection).length === 0
      ) {
        return;
      }
      for (const userId of Object.keys(rowSelection)) {
        await cloudService.run(
          KNOWN_CLOUD_FUNCTIONS.GRANT_OR_REVOKE_EXTERNAL_ROLE,
          JSON.parse(
            JSON.stringify({
              userId,
              operation,
              roles: Object.values(ExternalUserRoles).filter((_, index) =>
                selectionIndexes.includes(index),
              ),
            }),
          ),
        );
      }
      await dataFetcher();
      operation === RoleOperation.ADD
        ? setGrantRoleSelectionIndexes([])
        : setRevokeRoleSelectionIndexes([]);
    },
    [
      grantRoleSelectionIndexes,
      revokeRoleSelectionIndexes,
      rowSelection,
      dataFetcher,
      cloudService,
    ],
  );

  /**
   * Helper to get row id for row selection
   */
  const getRowId = (row: CustomTableColumns, index: number): string => {
    return row.id ?? index.toString();
  };

  return (
    <div style={styles.container}>
      <div style={styles.rowContainer}>
        <h1 style={styles.titleText}>{i18n.t('setExternalUserRoles.title')}</h1>
        <div style={styles.btnGroup}>
          <DropDownMultiSelect
            options={Object.values(ExternalUserRoles)}
            labels={Object.values(ExternalUserRoles).map(r =>
              i18n.t(`setExternalUserRoles.roles.${r}`),
            )}
            selectedIndexes={grantRoleSelectionIndexes}
            setSelectedIndexes={setGrantRoleSelectionIndexes}
            placeholder={i18n.t('setExternalUserRoles.setRole')}
            style={styles.dropdownButton}
            extraChildren={
              <ButtonRow
                buttons={[
                  {
                    title: i18n.t('globals.cancel'),
                    onClick: () => {
                      setGrantRoleSelectionIndexes([]);
                    },
                    style: { ...styles.itemBtn, ...styles.transparentBg },
                  },
                  {
                    title: i18n.t('globals.set'),
                    onClick: () => onSelectRoles(RoleOperation.ADD),
                    style: { ...styles.itemBtn },
                  },
                ]}
                style={styles.itemBtnContainer}
              />
            }
          />

          <DropDownMultiSelect
            options={Object.values(ExternalUserRoles)}
            labels={Object.values(ExternalUserRoles).map(r =>
              i18n.t(`setExternalUserRoles.roles.${r}`),
            )}
            selectedIndexes={revokeRoleSelectionIndexes}
            setSelectedIndexes={setRevokeRoleSelectionIndexes}
            placeholder={i18n.t('setExternalUserRoles.removeRole')}
            style={styles.dropdownButton}
            extraChildren={
              <ButtonRow
                buttons={[
                  {
                    title: i18n.t('globals.cancel'),
                    onClick: () => {
                      setRevokeRoleSelectionIndexes([]);
                    },
                    style: { ...styles.itemBtn, ...styles.transparentBg },
                  },
                  {
                    title: i18n.t('globals.remove'),
                    onClick: () => onSelectRoles(RoleOperation.REMOVE),
                    style: { ...styles.itemBtn },
                  },
                ]}
                style={styles.itemBtnContainer}
              />
            }
          />
          <ExportDropDown
            style={{ ...styles.rightButton, ...styles.exportButton }}
          />
        </div>
      </div>
      <Table
        columnHelper={columnHelper}
        columns={ROLE_COLUMNS}
        sorting={sorting}
        setSorting={setSorting}
        data={tableData}
        selectable={true}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        getRowId={getRowId}
      />
    </div>
  );
}
