import { useCallback } from 'react';
import Parse from '../parse';

import { useAuth } from '../providers/AuthProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useErrorHandling<T extends (...args: any[]) => unknown>(
  callback: T,
  deps: unknown[],
): T {
  const { logout } = useAuth();

  const memoizedCallback = useCallback(
    async (...args: Parameters<T>) => {
      try {
        return await callback(...args);
      } catch (e) {
        if (
          e instanceof Parse.Error &&
          (e.code === Parse.Error.INVALID_SESSION_TOKEN ||
            e.code === Parse.Error.INVALID_LINKED_SESSION)
        ) {
          await logout();
          return await callback(...args);
        }
        throw e;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout, ...deps],
  );

  return memoizedCallback as T;
}
