import type React from 'react';
import { type Header } from '@tanstack/react-table';
import {
  type CustomTableColumns,
  SortOrder,
  type SortedCol,
  CustomTableColumnKeys,
} from '../../../types/tables';
import { ThemeContent, StyleSheet } from '../../../providers/ThemeProvider';

/**
 * Sort the column when the header is clicked
 * @param header The header that was clicked
 */
export const onSortColumn = (
  header: Header<CustomTableColumns, unknown>,
  sorting?: SortedCol[],
  setSorting?: React.Dispatch<React.SetStateAction<SortedCol[]>>,
) => {
  // dont sort if the column is not sortable
  if (header.column.columnDef.meta?.data?.sortable === false) {
    return;
  }

  if (!sorting || !setSorting) {
    console.warn('Sorting is not defined');
    return;
  }

  // check if the column is already sorted
  const index = sorting.findIndex(sort => sort.colKey === header.id);
  // if it is, check if it is ascending or descending
  if (index > -1) {
    if (sorting[index].order === SortOrder.ASC) {
      // if it is ascending, change it to descending
      sorting[index].order = SortOrder.DESC;
      // let the header know that it is being sorted
      header.column.toggleSorting(true, true);
    } else {
      // if it is descending, remove it from the sorting
      sorting.splice(index, 1);
      // let the header know that it no longer being sorted
      header.column.clearSorting();
    }
  } else {
    // if it isn't, add it to the sorting as ascending
    sorting.push({
      colKey: header.id as keyof CustomTableColumnKeys,
      index: header.index,
      order: SortOrder.ASC,
    });
    // let the header know that it is being sorted
    header.column.toggleSorting(false, true);
  }
  // set the sorting
  setSorting([...sorting]);
};

export const commonTableStyles = (theme: ThemeContent): StyleSheet => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: '100%',
    marginTop: 15,
    marginBottom: 15,
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    gap: 10,
  },
  tableSectionContainer: {
    flexDirection: 'column',
  },
  h2: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  tableTopRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em 0',
    width: '100%',
  },
  exportButton: {
    backgroundColor: theme.colors.white,
    color: theme.colors.primary,
    // shorthand is not overriding for some reason?
    borderColor: theme.colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
  },
});
