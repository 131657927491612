import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  nav: {
    backgroundColor: theme.colors.primary,
  },
  ul: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    width: '98%',
    padding: '0 1% 0',
  },
  li: {
    marginLeft: '1%',
    color: theme.colors.white,
    fontSize: '0.875rem',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '20%',
    alignItems: 'flex-end',
  },
  logo: {
    marginRight: 'auto',
  },
  primaryButton: {
    borderStyle: 'solid',
    borderColor: theme.colors.greyWhite,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: '500',
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  subText: {
    fontSize: '0.8rem',
    marginTop: '2%',
  },
});
