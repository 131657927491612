import Parse from '../parse';
import { SensorAttributes } from '../schemas/Sensor';

export enum TeleFootXRoles {
  // Therapist
  Therapist = 'Therapist', // external, Can can create, edit, an update data related to other therapists and subjects'
  TherapistRead = 'TherapistRead', // A role to grant read access to therapist objects
  TherapistWrite = 'TherapistWrite', // A role to grant write access to therapist objects
  // Subject
  Subject = 'Subject', // external, Can create, edit, and update data related to themselves
  SubjectRead = 'SubjectRead', // A role to grant read access to subject objects
  SubjectWrite = 'SubjectWrite', // A role to grant write access to subject objects
  // StudyCoordinator
  // external, Can can create, edit, an update data related to other therapists and subjects
  StudyCoordinator = 'StudyCoordinator',
  StudyCoordinatorRead = 'StudyCoordinatorRead', // A role to grant read access to study coordinator objects
  StudyCoordinatorWrite = 'StudyCoordinatorWrite', // A role to grant write access to study coordinator objects
  // Operations Manager
  // Can can create, edit, an update data related to other all roles except the InternalCreationManager
  OperationsManager = 'OperationsManager',
  OperationsManagerRead = 'OperationsManagerRead', // A role to grant read access to operations manager objects
  OperationsManagerWrite = 'OperationsManagerWrite', // A role to grant write access to operations manager objects
  // Internal Creation Manager
  InternalCreationManager = 'InternalCreationManager', // Can grant the OperationsManager role
  // A role to grant read access to internal creation manager objects
  InternalCreationManagerRead = 'InternalCreationManagerRead',
  // A role to grant write access to internal creation manager objects
  InternalCreationManagerWrite = 'InternalCreationManagerWrite',
  // A role for PAMAccess to upload backed up sensor data
  PAMAccess = 'PAMAccess',
}

export enum ExternalUserRoles {
  Therapist = 'Therapist',
  StudyCoordinator = 'StudyCoordinator',
}

export enum RoleOperation {
  ADD = 'add',
  REMOVE = 'remove',
}

export type GetNonSubjectUsersResponse = {
  user: Parse.User;
  roles: TeleFootXRoles[];
}[];

/**
 * One one role for now, but might be extended later
 * Not including internal creation managers because no one can grant that role
 */
export enum InternalUserRoles {
  // Can grant the OperationsManager role
  InternalCreationManager = 'InternalCreationManager',
  // Can can create, edit, an update data
  // related to other all roles except the InternalCreationManager
  OperationsManager = 'OperationsManager',
  // Can create sensor backups
  PAMAccess = 'PAMAccess',
}

export interface UserTemporaryAuthToken extends Parse.Attributes {
  token: string;
  expiresAt: Date;
  forUser: Parse.User;
  createdBy: Parse.User;
  usedAt?: Date;
}

export interface AddSensorResponse {
  successes: Partial<SensorAttributes>[];
  failures: Partial<SensorAttributes>[];
}

export enum Side {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
