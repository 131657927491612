import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TeleFootXRoles } from '../types/cloud';
import Parse from '../parse';
import { DateTime } from 'luxon';
import axios from 'axios';
import base64 from 'base-64';

export enum LogLevel {
  ERROR,
  WARN,
  INFO,
  DEBUG,
  VERBOSE,
}

interface MezmoMetaData {
  session_id: string | undefined;
  user: {
    id: string | undefined;
    username: string | undefined;
    email: string | undefined;
    roles: string | undefined;
  };
  browser: string | undefined;
  env: 'production' | 'development' | 'test';
  app_name: string | undefined;
  app_id: string | undefined;
  parse_server_url: string | undefined;
  location: Location;
}

export type LoggingContent = {
  sendToMezmo: (level: LogLevel, message: string) => void;
  setUser: (user: Parse.User | undefined) => void;
  setRoles: (roles: TeleFootXRoles[] | undefined) => void;
  setSessionId: (id: string) => void;
};

const formatMessage = (tag: string, args: unknown[]) => {
  return `[${tag}] ${args.map(a => JSON.stringify(a)).join(' ')}`;
};

// from https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
function getBrowserName(userAgent: string) {
  // The order matters here, and this may report false positives for unlisted browsers.
  if (userAgent.includes('Firefox')) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return 'Mozilla Firefox';
  } else if (userAgent.includes('SamsungBrowser')) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011)
    // AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return 'Samsung Internet';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko)
    // Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return 'Opera';
  } else if (userAgent.includes('Edge')) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
    // Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return 'Microsoft Edge (Legacy)';
  } else if (userAgent.includes('Edg')) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
    // Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return 'Microsoft Edge (Chromium)';
  } else if (userAgent.includes('Chrome')) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko)
    // Chrome/104.0.0.0 Safari/537.36"
    return 'Google Chrome or Chromium';
  } else if (userAgent.includes('Safari')) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15
    // (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return 'Apple Safari';
  } else {
    return 'unknown';
  }
}

const LoggingContext = React.createContext<LoggingContent>(undefined!);

const LoggingProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<Parse.User>();
  const [roles, setRoles] = useState<TeleFootXRoles[]>();
  const [sessionId, setSessionId] = useState<string>();
  const logMetaData = useRef<MezmoMetaData>();

  useEffect(() => {
    const userInfo = {
      id: user?.id,
      username: user?.getUsername(),
      email: user?.getEmail(),
      roles: roles ? JSON.stringify(roles) : '',
    };
    const parse_server_url = process.env.REACT_APP_PUBLIC_SERVER_URL;
    const browser = getBrowserName(navigator.userAgent);
    const app_name = process.env.REACT_APP_APP_NAME;
    const app_id = process.env.REACT_APP_APP_ID;
    const env = process.env.NODE_ENV;
    const location = window.location;

    logMetaData.current = {
      session_id: sessionId,
      user: userInfo,
      browser,
      env,
      app_name,
      app_id,
      parse_server_url,
      location,
    };
  }, [user, roles, sessionId]);

  const sendToMezmo = useCallback(async (level: LogLevel, message: string) => {
    if (!logMetaData.current) {
      return;
    }
    const mezmoURL = process.env.REACT_APP_MEZMO_URL;
    const mezmoHostname = process.env.REACT_APP_MEZMO_HOSTNAME;
    const mezmoKey = process.env.REACT_APP_MEZMO_KEY;

    if (!mezmoURL || !mezmoHostname || !mezmoKey) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Mezmo environment variables not set');
      }
      return;
    }

    const now = DateTime.local();

    const body = {
      lines: [
        {
          timestamp: now.toMillis(),
          line: message,
          level,
          app: logMetaData.current.app_name,
          meta: {
            ...logMetaData.current,
            local_time: now.toISO(),
          },
        },
      ],
    };

    const base64Key = base64.encode(mezmoKey);

    try {
      await axios.post(mezmoURL, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64Key}`,
        },
        params: {
          hostname: mezmoHostname,
          now: now.toMillis(),
        },
      });
    } catch (e) {
      console.error('Error sending log to Mezmo', e);
    }
  }, []);

  return (
    <LoggingContext.Provider
      value={{
        sendToMezmo,
        setUser,
        setRoles,
        setSessionId,
      }}>
      {children}
    </LoggingContext.Provider>
  );
};

const useLogging = (tag: string) => {
  const { sendToMezmo } = useContext(LoggingContext);

  const error = useCallback(
    (...args: unknown[]) => {
      console.error(formatMessage(tag, args));
      sendToMezmo(LogLevel.ERROR, formatMessage(tag, args));
    },
    [tag, sendToMezmo],
  );

  const warn = useCallback(
    (...args: unknown[]) => {
      console.warn(formatMessage(tag, args));
      sendToMezmo(LogLevel.WARN, formatMessage(tag, args));
    },
    [sendToMezmo, tag],
  );

  const log = useCallback(
    (...args: unknown[]) => {
      console.log(formatMessage(tag, args));
      sendToMezmo(LogLevel.INFO, formatMessage(tag, args));
    },
    [sendToMezmo, tag],
  );

  const debug = useCallback(
    (...args: unknown[]) => {
      console.debug(formatMessage(tag, args));
      sendToMezmo(LogLevel.DEBUG, formatMessage(tag, args));
    },
    [sendToMezmo, tag],
  );

  const verbose = useCallback(
    (...args: unknown[]) => {
      console.debug(formatMessage(tag, args));
      sendToMezmo(LogLevel.VERBOSE, formatMessage(tag, args));
    },
    [sendToMezmo, tag],
  );

  return useMemo(
    () => ({ error, warn, log, debug, verbose }),
    [error, warn, log, debug, verbose],
  );
};

export { LoggingProvider, useLogging, LoggingContext };
