import {
  type StyleSheet,
  useThemedComponent,
  ThemeContent,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import Parse from 'parse';

const CONTACT_EMAIL = 'help+strokewear@biosensics.com';

export default function UnassignedUser() {
  const { styles } = useThemedComponent([UnassignedUserStyles]);
  return (
    <div style={styles.container}>
      <h1 style={styles.titleText}>
        {i18n.t('welcome', { username: Parse.User.current()?.getUsername() })}
      </h1>
      <hr style={styles.divider} />
      <label>{i18n.t('pleaseReachOut', { email: CONTACT_EMAIL })}</label>
    </div>
  );
}

const UnassignedUserStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '95%',
    padding: 30,
    gap: 10,
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  divider: {
    borderTopWidth: '1px',
    borderTopColor: theme.colors.coolGrey,
    width: '100%',
  },
});
