import { useNavigate } from 'react-router-dom';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import {
  type StyleSheet,
  useThemedComponent,
  ThemeContent,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import { useLoading } from '../../../providers/LoadingProvider';
import { useEffect } from 'react';

export default function NotFound() {
  const { styles } = useThemedComponent([NotFoundStyles]);
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <div style={styles.container}>
      <h1 style={styles.titleText}>{i18n.t('404error')}</h1>
      <h1 style={styles.subtitleText}>{i18n.t('notFound')}</h1>
      <hr style={styles.divider} />
      <label>{i18n.t('notFoundMsg')}</label>
      <ButtonRow
        buttons={[
          {
            title: i18n.t('goToHome'),
            onClick: () => {
              navigate('/');
            },
            style: styles.backButton,
          },
        ]}
        style={styles.buttonRow}
      />
    </div>
  );
}

const NotFoundStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '95%',
    padding: 30,
    gap: 10,
  },
  titleText: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  subtitleText: {
    fontSize: 24,
  },
  divider: {
    borderTopWidth: '1px',
    borderTopColor: theme.colors.coolGrey,
    width: '100%',
  },
  backButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: 5,
    width: 250,
    height: 40,
    borderStyle: 'solid',
  },
  buttonRow: {
    marginTop: 64,
  },
});
