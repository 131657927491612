import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { UnionToIntersection } from '../utils/type';

const colors = {
  blue: '#509ED7',
  green: '#5ABA4A',
  background: '#EFEFEF',
  white: '#FFFFFF',
  primary: '#2D3D50',
  red: '#E74C3C',
  yellow: '#F7CA18',
  orange: '#F37336',
  pink: '#D98DBC',
  gray: '#E5E5EA',
  disabled: '#C4C4C7',
  greySecondary: '#3C3C4399',
  popupBackground: '#00000099',
  transparentWhite: '#FFFFFFA6',
  coolGrey: '#C5D0DE',
  transparentBlackShadow: '#00000020',
  greyWhite: '#F9F9F9',
  transparentBlack: '#00000057',
  softBlue: '#7490B1',
  transparentGrey: '#D9D9D94D',
  navy: '#506C8E',
  black: '#000000',
  blackGrey: '#000000AD',
  aliceBlue: '#ecf2f8',
};

export interface ThemeContent {
  colors: typeof colors;
}

export type StyleSheet = Record<string, React.CSSProperties>;

// Define the type for the theme context
interface ThemeContextType {
  theme: ThemeContent;
  styles: React.CSSProperties;
  toggleTheme: () => void;
}

// Create a context for the theme
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Create a custom hook to use the theme context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

// Define your base styles
const baseStyles: React.CSSProperties = {
  fontFamily: 'Arial, sans-serif',
};

// Create a ThemeProvider component
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  // Default theme
  const [theme, setTheme] = useState<ThemeContent>({
    colors,
  });

  // Function to toggle between light and dark themes
  const toggleTheme = useCallback(() => {
    setTheme(prevTheme => ({
      ...prevTheme,
      // override the colors object with the new theme
    }));
  }, []);

  return (
    <ThemeContext.Provider value={{ styles: baseStyles, theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemedComponent = <
  T extends
    | Array<(theme: ThemeContent) => React.CSSProperties>
    | ((theme: ThemeContent) => React.CSSProperties),
>(
  styleSheet: T,
) => {
  const { theme } = useTheme();

  const styles = useMemo(() => {
    if (!Array.isArray(styleSheet)) {
      return styleSheet(theme);
    }
    const allStyles = styleSheet.reduce(
      (
        res: React.CSSProperties,
        item: (theme: ThemeContent) => React.CSSProperties,
      ) => {
        return {
          ...res,
          ...item(theme),
        };
      },
      {},
    );
    return allStyles as UnionToIntersection<ReturnType<ReturnTypeUnion>>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  // Compute the intersection of all return types of the ThemedStyle functions
  type ReturnTypeUnion = T extends Array<infer U> ? U : T;
  type IntersectionType = UnionToIntersection<ReturnType<ReturnTypeUnion>>;

  return { theme, styles: styles as IntersectionType };
};
