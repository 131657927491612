import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom'; // Or /native
import { AuthProvider } from './providers/AuthProvider';
import App from './App';
import Login from './pages/unauthorized/Login/Login';
import { RouterLoadingProvider } from './providers/RouterLoadingProvider';
import UnassignedUser from './pages/unauthorized/UnassignedUser/UnassignedUser';
import AddSensor from './pages/authorized/AddSensor/AddSensor';
import Google from './pages/auth/Google';
import SetExternalUserRoles from './pages/authorized/SetExternalUserRoles/SetExternalUserRoles';
import SetInternalUserRoles from './pages/authorized/SetInternalUserRoles/SetInternalUserRoles';
import Microsoft from './pages/auth/Micosoft';
import NotFound from './pages/unauthorized/NotFound/NotFound';
import { CloudProvider } from './providers/CloudProvider';
import ProtectedRoute, { ROUTES_TYPE } from './routes/ProtectedRoute';
import { LoggingProvider } from './providers/LoggingProvider';

const authorizedStack = (
  <RouterLoadingProvider>
    <LoggingProvider>
      <AuthProvider>
        <CloudProvider>
          <App />
        </CloudProvider>
      </AuthProvider>
    </LoggingProvider>
  </RouterLoadingProvider>
);

const unauthorizedStack = (
  <LoggingProvider>
    <AuthProvider>
      <Login />
    </AuthProvider>
  </LoggingProvider>
);

const googleStack = (
  <LoggingProvider>
    <AuthProvider>
      <Google />
    </AuthProvider>
  </LoggingProvider>
);

const microsoftStack = (
  <LoggingProvider>
    <AuthProvider>
      <Microsoft />
    </AuthProvider>
  </LoggingProvider>
);

export const ROUTES = {
  HOME: route(''),
  UNASSIGNED_USER: route('unassignedUser'),
  LOGIN: route('login'),
  SET_EXTERNAL_ROLES: route('setRoles/external'),
  SET_INTERNAL_ROLES: route('setRoles/internal'),
  ADD_SENSOR: route('addSensor'),
  OAUTH_GOOGLE_REDIRECT: route('oauth/google/code'),
  OAUTH_MICROSOFT_REDIRECT: route('oauth/microsoft/code'),
};

// If the relative url changes it must also be updated in 'homepage' in the package.json to build correctly
export const PORTAL_RELATIVE_URL = '/portal';

const NO_ROLES_ROUTES = [
  {
    path: ROUTES.UNASSIGNED_USER.path,
    element: <UnassignedUser />,
  },
  // add more routes here for no role users only
];

const INTERNAL_ROUTES = [
  {
    path: ROUTES.SET_EXTERNAL_ROLES.path,
    element: <SetExternalUserRoles />,
  },
  {
    path: ROUTES.ADD_SENSOR.path,
    element: <AddSensor />,
  },
  // add more routes here for internal users
];

const INTERNAL_CREATION_ROUTES = [
  {
    path: ROUTES.SET_INTERNAL_ROLES.path,
    element: <SetInternalUserRoles />,
  },
  // add more routes here for internal creation manager users
];

export const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME.path,
      element: authorizedStack,
      errorElement: <NotFound />,
      children: [
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.noRoles} />,
          children: NO_ROLES_ROUTES,
        },
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.internal} />,
          children: INTERNAL_ROUTES,
        },
        {
          element: (
            <ProtectedRoute
              allowedRoles={ROUTES_TYPE.internalCreationManagerOnly}
            />
          ),
          children: INTERNAL_CREATION_ROUTES,
        },
      ],
    },
    {
      path: ROUTES.LOGIN.path,
      element: unauthorizedStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_GOOGLE_REDIRECT.path,
      element: googleStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_MICROSOFT_REDIRECT.path,
      element: microsoftStack,
      errorElement: <NotFound />,
    },
  ],
  { basename: PORTAL_RELATIVE_URL },
);
