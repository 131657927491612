import i18n from '../../../translations';
import {
  ExternalUserRoles,
  GetNonSubjectUsersResponse,
} from '../../../types/cloud';
import {
  type ExternalUserRolesColumns,
  type CustomColumnDefintion,
} from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const ROLE_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'externalUser',
    headerName: i18n.t('setExternalUserRoles.externalUser'),
  },
  { column: 'dateAdded', headerName: i18n.t('setExternalUserRoles.dateAdded') },
  {
    column: 'role',
    headerName: i18n.t('setExternalUserRoles.role'),
  },
];

export const formatAllNonSubjectUsers = async (
  users: GetNonSubjectUsersResponse,
) => {
  const userRows: ExternalUserRolesColumns[] = users.map(
    ({ user, roles }) =>
      ({
        id: user.id, // this is used for row selection
        externalUser: user.get('username'),
        dateAdded: user.createdAt,
        // only show external roles and after translation
        role: roles
          .filter(r => Object.keys(ExternalUserRoles).includes(r))
          .map(r => i18n.t(`setExternalUserRoles.roles.${r}`)),
      }) as ExternalUserRolesColumns,
  );
  return userRows;
};
