import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import Parse from '../parse';
import { useAppEventService } from '../schemas/AppEvent';
import { useProfileService } from '../schemas/Profile';
import { useSensorService } from '../schemas/Sensor';
import { useSensorEventService } from '../schemas/SensorEvent';
import useErrorHandling from '../hooks/useErrorHandling';
import { TableRequest } from '../types/tables';
import { TeleFootXRoles } from '../types/cloud';
import { useLogging } from './LoggingProvider';

/**
 * Known cloud functions that can be run
 */
export const KNOWN_CLOUD_FUNCTIONS = {
  PING: 'ping',
  SET_SECONDARY_THERAPIST: 'SetSecondaryTherapist',
  SUBJECT_SUMMARY_TABLE: 'SubjectSummaryTable',
  HOME_SUMMARY_TABLE: 'HomeSummaryTable',
  SENSOR_HISTORY_TABLE: 'SensorHistoryTable',
  GDM_INFO_TABLE: 'GDMInformationTable',
  HEP_INFO_TABLE: 'HEPInformationTable',
  SWAP_SENSOR: 'SwapSensor',
  GET_NON_SUBJECT_USERS: 'GetNonSubjectUsers',
  GRANT_OR_REVOKE_INTERNAL_ROLE: 'GrantOrRevokeInternalRole',
  GRANT_OR_REVOKE_EXTERNAL_ROLE: 'GrantOrRevokeExternalRole',
  CREATE_PROFILE: 'CreateProfile',
  UPDATE_PROFILE: 'UpdateProfile',
  GENERATE_LOGIN_TOKEN: 'GenerateLoginToken',
  GET_USERS_WITH_ROLE: 'GetUsersWithRole',
  ADD_OR_REMOVE_SENSOR: 'AddOrRemoveSensor',
  CURRENT_DAAP_TABLE: 'CurrentDAAPTable',
  COMPLETED_DAAP_TABLE: 'CompletedDAAPTable',
  DAAP_HISTORY_TABLE: 'DAAPHistoryTable',
  SENSOR_WEAR_WEEKLY_TABLE: 'SensorWearWeeklyTable',
  SENSOR_WEAR_DAILY_TABLE: 'SensorWearDailyTable',
  COMPLETED_DAAP_HISTORY_TABLE: 'CompletedDAAPHistoryTable',
} as const;

export type CloudContent = {
  appEventService: ReturnType<typeof useAppEventService>;
  profileService: ReturnType<typeof useProfileService>;
  sensorService: ReturnType<typeof useSensorService>;
  sensorEventService: ReturnType<typeof useSensorEventService>;
  cloudService: ReturnType<typeof useCloudService>;
};

const CloudContext = createContext<CloudContent>(undefined!);

const CloudProvider = ({ children }: { children: ReactNode }) => {
  const appEventService = useAppEventService();
  const profileService = useProfileService();
  const sensorService = useSensorService();
  const sensorEventService = useSensorEventService();
  const cloudService = useCloudService();

  return (
    <CloudContext.Provider
      value={{
        appEventService,
        profileService,
        sensorService,
        sensorEventService,
        cloudService,
      }}>
      {children}
    </CloudContext.Provider>
  );
};

const useCloudContext = () => useContext(CloudContext);

export { useCloudContext, CloudProvider };

function useCloudService() {
  const logger = useLogging(useCloudService.name);

  const run = useErrorHandling(
    async <T,>(
      functionName: string,
      params?: JSON,
      log: boolean = true,
    ): Promise<T> => {
      if (log) {
        logger.debug(
          `Running cloud function ${functionName} with params ${JSON.stringify(params)}`,
        );
      }
      return Parse.Cloud.run(functionName, params);
    },
    [logger],
  );

  const prepareRquest = useCallback(<T extends object>(req: T) => {
    return JSON.parse(JSON.stringify(req));
  }, []);

  /**
   * Quick helper function to prepare a request for the cloud function
   * @param req The request to prepare
   * @returns A JSON object that can be sent to the cloud function
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const prepareTableRquest = useCallback(
    <T extends TableRequest>(req: T) => {
      return prepareRquest(req);
    },
    [prepareRquest],
  );

  const getServerTime = useCallback(async (): Promise<Date> => {
    const time = await run<string>(KNOWN_CLOUD_FUNCTIONS.PING);
    return new Date(time);
  }, [run]);

  const getUsersWithRole = useCallback(
    async (role: TeleFootXRoles) => {
      const res = await run<Promise<Array<Parse.Object<Parse.Attributes>>>>(
        KNOWN_CLOUD_FUNCTIONS.GET_USERS_WITH_ROLE,
        prepareRquest({ role }),
      );
      return res;
    },
    [prepareRquest, run],
  );

  return useMemo(
    () => ({
      run,
      getServerTime,
      getUsersWithRole,
    }),
    [getServerTime, getUsersWithRole, run],
  );
}
