import Parse from '../parse';
import { OwnableObject } from './interfaces/OwnableObject';

export enum APP_EVENT_TYPE {
  ACTIVE,
  BACKGROUND,
  INACTIVE,
}

/**
 * Records any specified event that happens in the app.
 * e.g. opening the app, closing the app, etc.
 */
export interface AppEventAttributes extends OwnableObject {
  time: Date;
  event: APP_EVENT_TYPE;
  meta?: Parse.JSONBaseAttributes | Parse.RawJSONOptions;
}

export const classname = 'AppEvent';

export class AppEvent extends Parse.Object<AppEventAttributes> {
  constructor(attributes: AppEventAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, AppEvent);

export function useAppEventService() {
  return {};
}
