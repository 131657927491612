import React, { useCallback, useEffect } from 'react';
import { FormInput } from '../../../components/atoms/FormInput/FormInput';
import { Form, Formik, FormikHelpers } from 'formik';
import { ROLE_COLUMNS, formatSensors } from './helpers';
import i18n from '../../../translations';
import * as Yup from 'yup';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import AddSensorStyles from './AddSensorStyles';
import Table from '../../../components/templates/Table/Table';
import {
  CustomTableColumns,
  SensorColumns,
  SortOrder,
} from '../../../types/tables';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import { useTableColumns } from '../../../hooks/useTableColumns';
import { useLoading } from '../../../providers/LoadingProvider';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import { AddSensorResponse } from '../../../types/cloud';
import { Sensor } from '../../../schemas/Sensor';

export interface AddSensorFields {
  mac: string;
  serialNumber: string;
  batteryNumber: string;
  weldNumber: string;
}

const initialValues: AddSensorFields = {
  mac: '',
  serialNumber: '',
  batteryNumber: '',
  weldNumber: '',
};

export enum SensorOperation {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

// modify error messages in the translation json file
const addSensorSchema = Yup.object<AddSensorFields>().shape({
  mac: Yup.string()
    .trim()
    .matches(
      new RegExp('^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$'),
      { message: i18n.t('addSensor.mac.invalidMac') },
    )
    .required(i18n.t('addSensor.mac.required')),
  serialNumber: Yup.string().required(
    i18n.t('addSensor.serialNumber.required'),
  ),
});

export default function AddSensor() {
  const { styles } = useThemedComponent([commonTableStyles, AddSensorStyles]);
  const { setLoading } = useLoading();
  const { sensorService, cloudService } = useCloudContext();

  const {
    columnHelper,
    tableData,
    setTableData,
    rowSelection,
    setRowSelection,
    ExportDropDown,
    sorting,
    setSorting,
  } = useTableColumns<SensorColumns>({
    defaultSorting: [{ colKey: 'dateAdded', index: 1, order: SortOrder.ASC }],
    tableName: i18n.t('addSensor.currentSensors'),
  });

  /**
   * Helper to get row id for row selection
   */
  const getRowId = (row: CustomTableColumns, index: number): string => {
    return row.id ?? index.toString();
  };

  const dataFetcher = useCallback(async () => {
    try {
      setLoading(true);
      const activeSensors = await sensorService.getAllActiveSensors();
      const sensors = formatSensors(activeSensors);
      if (sensors) {
        setTableData(sensors);
      }
    } catch (e) {
      alert('Failed to get sensors' + e);
    } finally {
      setLoading(false);
    }
  }, [sensorService, setLoading, setTableData]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  const onSubmit = useCallback(
    async (
      { mac, serialNumber, batteryNumber, weldNumber }: AddSensorFields,
      action: FormikHelpers<AddSensorFields>,
    ) => {
      try {
        setLoading(true);
        const { successes, failures } =
          await cloudService.run<AddSensorResponse>(
            KNOWN_CLOUD_FUNCTIONS.ADD_OR_REMOVE_SENSOR,
            JSON.parse(
              JSON.stringify({
                sensors: [{ mac, serialNumber, batteryNumber, weldNumber }],
                operation: SensorOperation.ADD,
              }),
            ),
          );
        document.getElementsByName('mac')[0].focus();
        await dataFetcher();
        action.resetForm();

        alert(`
        Successes: ${successes.map(s => s.mac)}
        Failures:  ${failures.map(s => s.mac)}
      `);
      } catch (e) {
        alert(`Failed to add sensor(s): ${e}`);
      } finally {
        setLoading(false);
      }
    },
    [cloudService, dataFetcher, setLoading],
  );

  const removeSensors = useCallback(async () => {
    try {
      setLoading(true);
      if (Object.keys(rowSelection).length === 0) {
        return;
      }
      const selectedSensors = (
        await Promise.all(
          Object.keys(rowSelection).map(
            async id => (await sensorService.getSensorById(id)) as Sensor,
          ),
        )
      ).map((sensor: Sensor) => ({
        mac: sensor.get('mac'),
        serialNumber: sensor.get('serialNumber'),
      }));
      await cloudService.run<AddSensorResponse>(
        KNOWN_CLOUD_FUNCTIONS.ADD_OR_REMOVE_SENSOR,
        JSON.parse(
          JSON.stringify({
            sensors: selectedSensors,
            operation: SensorOperation.REMOVE,
          }),
        ),
      );
      await dataFetcher();
      setRowSelection({});
    } catch (e) {
      alert('Error when removing sensors' + e);
    } finally {
      setLoading(false);
    }
  }, [
    cloudService,
    dataFetcher,
    rowSelection,
    sensorService,
    setLoading,
    setRowSelection,
  ]);

  return (
    <div style={styles.container}>
      <div style={styles.addSensorContainer}>
        <h1 style={{ ...styles.titleText, ...styles.extraRowSpacing }}>
          {i18n.t('addSensor.title')}
        </h1>
        <Formik
          validationSchema={addSensorSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}>
          {({ handleSubmit, resetForm }) => (
            <Form onSubmit={handleSubmit} style={styles.betweenInput}>
              <FormInput
                type="text"
                name={'mac'}
                label={i18n.t('addSensor.mac.label')}
                placeholder={i18n.t('addSensor.mac.placeholder')}
                autoFocus
                transformInput={event => {
                  if (event.currentTarget.value.length === 17) {
                    event.currentTarget.blur();
                    document.getElementsByName('serialNumber')[0].focus();
                  }
                  return event;
                }}
              />
              <FormInput
                type="text"
                name={'serialNumber'}
                label={i18n.t('addSensor.serialNumber.label')}
                placeholder={i18n.t('addSensor.serialNumber.placeholder')}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    e.currentTarget.blur();
                    document.getElementsByName('batteryNumber')[0].focus();
                  }
                }}
                transformInput={event => {
                  if (event.currentTarget.value.startsWith(',')) {
                    const newEvent = event;
                    newEvent.currentTarget.value =
                      newEvent.currentTarget.value.slice(1);
                    return newEvent;
                  }

                  return event;
                }}
              />
              <FormInput
                type="text"
                name={'batteryNumber'}
                label={i18n.t('addSensor.batteryNumber')}
                transformInput={event => {
                  if (event.currentTarget.value.startsWith('B:')) {
                    const newEvent = event;
                    newEvent.currentTarget.value = newEvent.currentTarget.value
                      .slice(3)
                      .trim();
                    return newEvent;
                  }

                  if (event.currentTarget.value.endsWith('W:')) {
                    const newEvent = event;
                    const length = event.currentTarget.value.length;
                    newEvent.currentTarget.value =
                      newEvent.currentTarget.value.slice(0, length - 3);
                    newEvent.currentTarget.blur();
                    document.getElementsByName('weldNumber')[0].focus();
                    return newEvent;
                  }

                  return event;
                }}
              />
              <FormInput
                type="text"
                name={'weldNumber'}
                label={i18n.t('addSensor.weldNumber')}
              />
              <ButtonRow
                buttons={[
                  {
                    title: i18n.t('globals.cancel'),
                    type: 'button',
                    style: styles.cancelButton,
                    onClick: () => {
                      resetForm();
                    },
                  },
                  {
                    title: i18n.t('globals.save'),
                    type: 'button',
                    style: styles.saveButton,
                    onClick: () => handleSubmit(),
                  },
                ]}
                style={styles.buttonContainer}
              />
            </Form>
          )}
        </Formik>
      </div>
      <div
        style={{
          ...styles.tableSectionContainer,
          ...styles.currentSensorsContainer,
        }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h1 style={styles.titleText}>{i18n.t('addSensor.currentSensors')}</h1>
          <div style={styles.btnGroup}>
            <button style={styles.removeBtn} onClick={removeSensors}>
              {i18n.t('globals.remove')}
            </button>
            <ExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          columnHelper={columnHelper}
          columns={ROLE_COLUMNS}
          sorting={sorting}
          setSorting={setSorting}
          data={tableData}
          selectable={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          getRowId={getRowId}
        />
      </div>
    </div>
  );
}
