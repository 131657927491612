import { Sensor, SensorPosition } from './Sensor';
import Parse from 'parse';
import { OwnableObject } from './interfaces/OwnableObject';
import useErrorHandling from '../hooks/useErrorHandling';
import { Profile } from './Profile';
import { useMemo } from 'react';
import { Side } from '../types/cloud';

/**
 * Keep track a full historically sensor record of an user.
 */
export interface SensorHistoryAttributes extends OwnableObject {
  sensor: Sensor;
  position: SensorPosition;
  paired: Date;
  unpaired?: Date;
  connected?: Date;
  affectedSide: boolean;
}

export const classname = 'SensorHistory';

export class SensorHistory extends Parse.Object<SensorHistoryAttributes> {
  constructor(attributes: SensorHistoryAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, SensorHistory);

/**
 * The return type of the below helper function to get left and right sensors of a given subject
 */
export interface SensorPair {
  left: Sensor;
  right: Sensor;
}

export function useSensorHistoryService() {
  const getSubjectsCurrentSensorHistory = useErrorHandling(
    async (profile: Profile) => {
      const sensorHistoryQuery = new Parse.Query(SensorHistory);
      sensorHistoryQuery.include('owner');
      sensorHistoryQuery.include('sensor');
      sensorHistoryQuery.equalTo('owner', profile);
      sensorHistoryQuery.doesNotExist('unpaired');
      sensorHistoryQuery.descending('updatedAt');
      sensorHistoryQuery.equalTo('position', SensorPosition.LEFT_WRIST);
      const leftHistory = await sensorHistoryQuery.first();

      sensorHistoryQuery.equalTo('position', SensorPosition.RIGHT_WRIST);
      const rightHistory = await sensorHistoryQuery.first();

      return {
        leftHistory,
        rightHistory,
      };
    },
    [],
  );

  const getSubjectsCurrentSensors = useErrorHandling(
    async (profile: Profile) => {
      const { leftHistory, rightHistory } =
        await getSubjectsCurrentSensorHistory(profile);

      if ((!leftHistory || !rightHistory) && !profile.get('deactivatedAt')) {
        throw new Error('No sensor found for the given profile');
      }

      return {
        left: leftHistory?.attributes.sensor,
        right: rightHistory?.attributes.sensor,
      };
    },
    [],
  );

  const getProfileAffectedSide = useErrorHandling(
    async (profile: Profile): Promise<Side> => {
      const sensorHistoryQuery = new Parse.Query(SensorHistory);
      sensorHistoryQuery.include('owner');
      sensorHistoryQuery.include('sensor');
      sensorHistoryQuery.equalTo('owner', profile);
      sensorHistoryQuery.descending('updatedAt');
      sensorHistoryQuery.equalTo('position', SensorPosition.LEFT_WRIST);
      const leftHistory = await sensorHistoryQuery.first();

      if (!leftHistory) {
        throw new Error('Cannot get Profile left sensor history');
      }

      return leftHistory.attributes.affectedSide ? Side.LEFT : Side.RIGHT;
    },
    [],
  );

  return useMemo(
    () => ({
      getSubjectsCurrentSensors,
      getProfileAffectedSide,
      getSubjectsCurrentSensorHistory,
    }),
    [
      getSubjectsCurrentSensors,
      getProfileAffectedSide,
      getSubjectsCurrentSensorHistory,
    ],
  );
}
